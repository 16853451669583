import React from "react";
import { Link } from "react-router-dom";
const Comingsoon = () => {
  return (
    <div className="cmMain">
      <div className="coming-soon">
        <div className="content">
          <h1>Coming Soon</h1>
          <p>We are working on something amazing! Stay tuned.</p>
        </div>
      </div>
    </div>
  );
};

export default Comingsoon;
