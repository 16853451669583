import React, { useEffect, useState } from "react";
// import { Card , CardBody } from "../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import { flexbox } from "@material-ui/system";
import { Card, Box } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography, Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DateFormatter } from "./DateFormatter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getRewardModel } from "./helper";
import * as actions from "../../campaign/_redux/campaignActions";
import ComponentLoader from "../../../components/ComponentLoader";
import { getCampaignType } from "./helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NavTab from "../components/NavTab";
const useStyles = makeStyles({
  root: {},
  media: {},
});

export const nFormatter = (num) => {
  if (num >= 1000000000) {
     return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 100000) {
     return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
  }
  if (num >= 1000) {
     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
//   if (num <= 1000) {
//     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
//  }
  return num;
}

export default function ActiveCampaignCard() {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const { currentState, loggedInUserId, listLoading, isRightSidebar, } = useSelector(
    (state) => ({
      currentState: state.campaign,
      loggedInUserId: state.auth.user.userId,
      listLoading: state.campaign.listLoading,
      isRightSidebar: state.common.isRightSidebar,
    }),
    shallowEqual
  );

  const { totalCount, entities, totalPages } = currentState;

  var date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  let expiryDate = date;

  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(
      actions.listCampaigns({
        frontendAction: "creator.campaigns.list",
        loggedInUserId: loggedInUserId,
        page: currentPage,
        pageSize: 12,
        sort: "-updatedAt",
        sortField: "id",
        sortOrder: "asc",
        trackingPriority: 0,
        expiryFilter: expiryDate,
        query: {
          brandId: loggedInUserId,
          campaignStatus: [
            "APPROVED_BY_ADMIN",
            "CAMPAIGN_APPROVED_IN_RESTRICTED_MODE",
          ],
          // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN",
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      {/* <div class="d-flex flex-row sx-4"> */}
      <div className="row mt-0">
        <div className="col-xl-12">
          <div className="card card-custom card-stretch  gutter-b">
            <div className="card-header">
              <div class="card-title">
                <h3 class="card-label">My Campaigns</h3>
              </div>

              {/* <input type="text" class="form-control bg-light-primary " placeholder="Search..." onChange={(e) => {
               setSearchText(e.target.value?.length > 0 ? e.target.value : "");
                }}
              value={searchText} /> */}
              <NavTab current="active" />
            </div>

            {listLoading ? (
              <ComponentLoader />
            ) : (
              entities?.length > 0 && (
                <div class="card-body">
                  <div class="row">
                    {entities?.map((item, index) => (
                      <div
                        class={` ${!isRightSidebar ? "col-lg-3 col-xs-12 col-sm-12 col-xl-3" : "col-lg-4 col-xs-12 col-sm-12 col-xl-4"}`}
                        key={index}
                        // onClick={() => {
                        //   history.push(
                        //     `/campaigns/active-list/${item?.campaignId}/view`
                        //   );
                        // }}
                      >
                        <div
                          className="d-flex justify-content-center cursor-pointer w-100 h-100"
                          onClick={() => {
                            history.push(
                              `/campaigns/list/${item?.campaignId}/view`
                            );
                          }}
                        >
                          <div
                            class="d_card w-100 d-flex align-content-between flex-wrap"
                            style={{
                              backgroundImage: item?.productImageUrl
                                ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                                    item?.productImageUrl})`
                                : "url(/media/dummyImages/no_preview.png",
                            }}
                          >
                            <div class="col-12 px-4 d-flex justify-content-end">
                              <span class="label label-outline-success label-pill label-inline label_dark">
                                {item?.campaignCategory}
                              </span>
                            </div>
                            <div class="col-12 px-4">
                              <p
                                class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                                title="Create Contents"
                              >
                                {item?.campaignName}
                              </p>
                              <div class="d-flex d-flex align-items-center">
                                <div class="flex-grow-1">
                                  <div class="d-flex align-items-center mb-0">
                                    <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                      <span class="text-light font-size-xs">
                                        <img
                                          class="mr-1 h-10px mt-n1"
                                          src="/media/svg/icons/custom/Icon-calendar.svg"
                                        />{" "}
                                        <span>
                                          {DateFormatter(
                                            item.campaignClosureTime
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {item?.rewardModel === "CASH" && (
                                <div class="flex-shrink-0">
                                  <span class="number text-theme font-weight-Bold font-size-h3">
                                    {item?.productCurrency == "INR" ? (
                                      <img
                                        class="mt-n1 mr-1"
                                        src="/media/custom/Icon_rupee_sign.svg"
                                      />
                                    ) : (
                                      <span>$</span>
                                    )}
                                    {nFormatter(item?.productValue)}
                                  </span>
                                </div>
                              )}
                                {item?.rewardModel === "PRODUCT" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="group-edit-tooltip">
                                        {item?.rewardProductDescription}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-secondary">
                                      {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
                                      <a
                                        href="#"
                                        class="btn bg-custom-primary btn-pill btn-sm"
                                      >
                                        Gift
                                      </a>
                                    </span>
                                  </OverlayTrigger>
                                )}
                                {item?.rewardModel === "OFFER" && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="group-edit-tooltip">
                                        {item?.rewardProductDescription}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-secondary">
                                      {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
                                      <a
                                        href="#"
                                        class="btn bg-custom-primary btn-pill btn-sm"
                                      >
                                        Offer
                                      </a>
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
            {entities?.length === 0 && (
              <div class="card-body">
                <div class="d-column row justify-content-center text-muted">
                  <h4 class="text-muted">No Campaigns Found</h4>
                </div>
              </div>
            )}
            {/* (
          <Box
          display="flex"
          flexWrap="wrap"
          alignContent="space-between"
          className="row"


          >
          {totalCount != 0 ? entities.map((item, index) => (
            <Box p={1} key={index} className="col-md-4">
              <Card className={classes.root} key={index} >
                <CardActionArea onClick={() => history.push(`/campaigns/list/${item.campaignId}/view`)}>
                  <CardMedia
                    className="bgi-no-repeat bgi-size-cover min-h-180px w-100 rounded-0"
                    alt=""
                    image={item?.productImageUrl ? `${process.env.REACT_APP_AWS_S3_BASE_URL + item.productImageUrl}` : "media/dummyImages/no_preview.png"}
                    title="Contemplative Reptile"
                    height="140"
                    />
                  <CardContent className="pb-0">
                    <Typography gutterBottom className="text-dark mt-2 font-size-h5  font-weight-bold col-12 pl-0 text-truncate">
                      {item.campaignName}
                    </Typography>
                    <Typography
                      className="label label-lg label-light-primary label-inline"
                      >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getCampaignType(item?.campaignType)
                        }}
                        ></div>

                    </Typography>
                    </CardContent>
                    </CardActionArea>
                    <Typography variant="body2" color="textSecondary" className="d-flex mt-3 justify-content-between" component="p">
                      <Box>
                        <i class="flaticon2-calendar-9 mr-2 font-size-lg"></i> <span className="font-size-sm">{DateFormatter(item.campaignClosureTime)}</span>
                      </Box>
                      <Button className="label label-inline px-0" onClick={() => history.push(`/applications/list/${item.campaignId}`)}>
                      </Button>
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary" component="p">

                      <Box className="font-size-h4 font-weight-bolder mt-3 text-primary text-right d-flex justify-content-around">
                      <Chip label={`New application - ${item.newCount} `} color="secondary" size="small" onClick={() => history.push(`/applications/list/${item.campaignId}`)}/>
                        {item.productValue === "0.00" ? "Gift/offers" : getRewardModel(item.productCurrency) + " " + item.productValue}
                      </Box>
                    </Typography>




                <CardActions>



                </CardActions>
              </Card>
            </Box>

         )) :
          <Typography variant="h4"
                      component="h2" >
            No Campaigns Found
          </Typography>
          }

        </Box>) */}
            {totalPages > 1 && !listLoading && (
              <div className="card-footer p-3 d-flex justify-content-center">
                  <Pagination
                    onChange={(e, p) => setCurrentPage(p)}
                    page={currentPage}
                    count={totalPages}
                    color="secondary"
                  />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
