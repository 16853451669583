import { FormatListBulletedOutlined } from "@material-ui/icons";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
// const currentYear = new Date();
export const initialSocialPostV2ApplicationState = {
  activeTab: 0,
  isLoadingFlag: false,
  brandId: "",
  campaignName: "",
  aboutCampaign: "",
  campaignPurpose: "PRODUCT_ASSOCIATED_BUSSSINESS",
  productImageUrl: "",
  productWebsite: "",
  productCategory: "",
  isProductSendToCreators: false,
  campaignType: "",

  styleSelectionProductPhotographyCountFlag: false,
  styleSelectionLifestyleProductInActionCountFlag: false,
  styleSelectionPortraitStylePhotographyCountFlag: false,
  styleSelectionGraphicDesignCountFlag: false,
  styleSelectionActionProductInUseCountFlag: false,
  styleSelectionUnboxingExplanationVideoCountFlag: false,
  styleSelectionProductReviewTestimonialVideoCountFlag: false,
  styleSelectionPromotionalVideoCountFlag: false,

  styleSelectionProductPhotographyCount: 0,
  styleSelectionLifestyleProductInActionCount: 0,
  styleSelectionPortraitStylePhotographyCount: 0,
  styleSelectionGraphicDesignCount: 0,
  selectedLayout: "",
  videoLength: "",
  styleSelectionActionProductInUseCount: 0,
  styleSelectionUnboxingExplanationVideoCount: 0,
  styleSelectionProductReviewTestimonialVideoCount: 0,
  styleSelectionPromotionalVideoCount: 0,
  styleGuideLine: "",
  styleGuideReferenceLinks: [],
  styleGuideReferenceFiles: [],
  targetGender: "ANY",
  targetAgeCategory: "ANY",
  requiredInstagramHandle: [],
  requiredInstagramHashTags: [],
  isInstagramStoryRequired: false,
  requiredYoutubeHashTags: [],
  isYoutubeStoryRequired: false,
  captionDetails: "",
  campaignClosureTime: "",

  rewardModel: "CASH",
  productCurrency: "INR",
  productValue: "",
  isInstagramPostRequired: false,
  isYoutubePostRequired: false,
  isAutoApprovalRequired: false,
  makeReach: false,
  growCustomerBase: false,
  campaignCategory: "",
  areaOfInterest: [],
  templateDescription: "",
  owner: "",
  socialMediaDetails: {},
  creatorDetails: {},
  viewCount: 0,
  campaignCategoryId: "",
  filterLevelIds: [],
  subFilterLevelIds: [],
  templateSummary: "",
  campaignDuration: 0,
  revisionCount: 0,
  rewardProductDescription: "",
};

export const SocialPostV2ApplicationSlice = createSlice({
  name: "socialPostApplication",
  initialState: initialSocialPostV2ApplicationState,
  reducers: {
    toggleLoadingFlag: (state, action) => {
      state.isLoadingFlag = Boolean(action.payload);
    },
    updateApplicationState: (state, action) => {
      for (const property in action?.payload) {
        state[property] = action?.payload?.[property];
      }
    },
    updateFilterLevelIds: (state, action) => {
      state.filterLevelIds = action.payload;
    },
    updateSubFilterLevelIds: (state, action) => {
      state.subFilterLevelIds = action.payload;
    },
    updateViewCount: (state, action) => {
      state.viewCount = action.payload;
    },
    updateCampaignCategory: (state, action) => {
      state.campaignCategory = action.payload;
    },
    updateMakeReach: (state, action) => {
      state.makeReach = action.payload;
    },
    updateGrowCustomerBase: (state, action) => {
      state.growCustomerBase = action.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateCreatorDetails: (state, action) => {
      state.creatorDetails = action.payload;
    },
    updateCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    getCampaignDetails: (state, action) => {
      let campaignDetails = action.payload.campaignDetails;
      // state.listLoading = false;
      // state.error = null;
      // state.campaignDetails = campaignDetails;
      state.campaignType = campaignDetails.campaignType;
      state.campaignCategory = campaignDetails.campaignCategory;
      state.areaOfInterest = campaignDetails.areaOfInterest;
      state.campaignName = campaignDetails.campaignName;
      state.aboutCampaign = campaignDetails.aboutCampaign;
      state.campaignPurpose = campaignDetails.campaignPurpose;
      state.productImageUrl = campaignDetails.productImageUrl;
      state.productWebsite = campaignDetails.productWebsite;
      state.productCategory = campaignDetails.productCategory;
      state.socialMediaDetails = campaignDetails.socialMediaDetails;
      state.revisionCount = campaignDetails.revisionCount;
      state.campaignDuration = campaignDetails.campaignDuration;
      state.templateSummary = campaignDetails.templateSummary;

      state.styleSelectionProductPhotographyCount = parseInt(
        campaignDetails.styleSelectionProductPhotographyCount
      );
      state.styleSelectionLifestyleProductInActionCount = parseInt(
        campaignDetails.styleSelectionLifestyleProductInActionCount
      );
      state.styleSelectionPortraitStylePhotographyCount = parseInt(
        campaignDetails.styleSelectionPortraitStylePhotographyCount
      );
      state.styleSelectionGraphicDesignCount = parseInt(
        campaignDetails.styleSelectionGraphicDesignCount
      );

      state.styleSelectionActionProductInUseCount = parseInt(
        campaignDetails.styleSelectionActionProductInUseCount
      );
      state.styleSelectionUnboxingExplanationVideoCount = parseInt(
        campaignDetails.styleSelectionUnboxingExplanationVideoCount
      );
      state.styleSelectionProductReviewTestimonialVideoCount = parseInt(
        campaignDetails.styleSelectionProductReviewTestimonialVideoCount
      );
      state.styleSelectionPromotionalVideoCount = parseInt(
        campaignDetails.styleSelectionPromotionalVideoCount
      );
      state.styleSelectionProductPhotographyCountFlag =
        campaignDetails.styleSelectionProductPhotographyCount !== "0" &&
        campaignDetails.styleSelectionProductPhotographyCount !== null
          ? true
          : false;
      state.styleSelectionLifestyleProductInActionCountFlag =
        campaignDetails.styleSelectionLifestyleProductInActionCount !== "0" &&
        campaignDetails.styleSelectionLifestyleProductInActionCount !== null
          ? true
          : false;
      state.styleSelectionPortraitStylePhotographyCountFlag =
        campaignDetails.styleSelectionPortraitStylePhotographyCount !== "0" &&
        campaignDetails.styleSelectionPortraitStylePhotographyCount !== null
          ? true
          : false;
      state.styleSelectionGraphicDesignCountFlag =
        campaignDetails.styleSelectionGraphicDesignCount !== "0" &&
        campaignDetails.styleSelectionGraphicDesignCount !== null
          ? true
          : false;

      state.styleSelectionActionProductInUseCountFlag =
        campaignDetails.styleSelectionActionProductInUseCount !== "0" &&
        campaignDetails.styleSelectionActionProductInUseCount !== null
          ? true
          : false;
      state.styleSelectionUnboxingExplanationVideoCountFlag =
        campaignDetails.styleSelectionUnboxingExplanationVideoCount !== "0" &&
        campaignDetails.styleSelectionUnboxingExplanationVideoCount !== null
          ? true
          : false;
      state.styleSelectionProductReviewTestimonialVideoCountFlag =
        campaignDetails.styleSelectionProductReviewTestimonialVideoCount !==
          "0" &&
        campaignDetails.styleSelectionProductReviewTestimonialVideoCount !==
          null
          ? true
          : false;
      state.styleSelectionPromotionalVideoCountFlag =
        campaignDetails.styleSelectionPromotionalVideoCount !== "0" &&
        campaignDetails.styleSelectionPromotionalVideoCount !== null
          ? true
          : false;

      state.styleGuideLine = campaignDetails.styleGuideLine;
      state.styleGuideReferenceLinks = campaignDetails.styleGuideReferenceLinks;

      let styleGuideReferenceFiles = campaignDetails.styleGuideReferenceFiles.map(
        (data) => {
          return {
            url: data?.split("/?")[0],
            file: data?.file,
            currentFile: { name: data?.split("/?")[1] },
          };
        }
      );

      state.styleGuideReferenceFiles = styleGuideReferenceFiles;
      state.campaignClosureTime = moment(
        campaignDetails.campaignClosureTime
      ).format("YYYY-MM-DD");
      state.productCurrency = campaignDetails.productCurrency;
      state.productValue = campaignDetails.productValue;
      state.targetGender = campaignDetails.targetGender;
      state.targetAgeCategory = campaignDetails.targetAgeCategory;
      state.requiredInstagramHandle = campaignDetails.requiredInstagramHandle;
      state.requiredInstagramHashTags =
        campaignDetails.requiredInstagramHashTags;
      state.isInstagramStoryRequired = campaignDetails.isInstagramStoryRequired;
      state.requiredYoutubeHashTags = campaignDetails.requiredYoutubeHashTags;
      state.isYoutubeStoryRequired = campaignDetails.isYoutubeStoryRequired;
      state.captionDetails = campaignDetails.captionDetails;
      state.selectedLayout = campaignDetails.selectedLayout || "";
      state.videoLength = campaignDetails.videoLength || "";
      state.activeTab = 0;
      state.isAutoApprovalRequired = campaignDetails.isAutoApprovalRequired;
      state.rewardModel = campaignDetails.rewardModel;
      state.rewardProductDescription = campaignDetails.rewardProductDescription;
      state.isLoadingFlag = false;
    },
  },
});
