import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  register,
  login,
  googleSignInorLogin,
  sendPhoneOtpForRegistration,
  completeRegistrationWithOtp,
} from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import { Toaster } from "../../../../app/components/Toaster";
import ComponentLoader from "../../../components/ComponentLoader";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "./index.scss"

function Registration(props) {
  const [credential, setCredential] = useState();
  // const registerData = props.history.location.state?.verifiedData?.data[0];
  const { intl } = props;
  const history = useHistory();
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [phoneStatus, setPhoneStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [phoneDetails, setPhoneDetails] = useState(null);
  const [error, setError] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [seconds, setSeconds] = useState(0);
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegistrationSchema = Yup.object().shape({
    // phoneNumber: Yup.string()
    //   // .matches(phoneRegExp, "Phone number is not valid")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).nullable(),
    // loginEmail: Yup.string()
    //   .email("Wrong email format")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).nullable(),
    // email: Yup.string()
    //   .email("Wrong email format")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).nullable(),
    phoneCode: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .nullable(),
    // firstName: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).nullable(),
    // lastName: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).nullable(),
    // password: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   )
    //   .nullable(),
    // changepassword: Yup.string()
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   )
    //   .when("password", {
    //     is: (val) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       "Password and Confirm Password didn't match"
    //     ),
    //   }),
    // acceptTerms: Yup.bool().required(
    //   "You must accept the terms and conditions"
    // ),
    companyName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Enter Company Name")
      .nullable(),
  });

  const emailRegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .nullable(),
    companyName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Enter Company Name")
      .nullable(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const verifyOtp = (paramsForLogin) => {
    enableLoading();
    completeRegistrationWithOtp(paramsForLogin)
      .then(({ data }) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        props.login(data?.data);
        disableLoading();
        // setSubmitting(false);
      })
      .catch((err) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        disableLoading();
        // setSubmitting(false);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("time exceed!");
    }
  }, [seconds]);

  let minutesTobeShown = Math.floor(seconds / 60);
  let secondsToBeShown = seconds - minutesTobeShown * 60;

  const move = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key === "Backspace") {
      if (p !== "") {
        document.getElementById(p).focus();
      }
    }
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: phoneStatus
      ? phoneRegistrationSchema
      : emailRegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let { category, ...rest } = values;
      setSubmitting(true);
      enableLoading();
      // values["userId"] = registerData?.userId;
      setPhoneDetails({
        phoneNumber: rest.phoneNumber,
        phoneCode: rest.phoneCode,
        email: rest.email,
      });
      let objToSend = {
        ...rest,
      };
      sendPhoneOtpForRegistration(objToSend)
        .then(({ data }) => {
          setSeconds(30);
          setIsOtp(true);
          Toaster({ message: "OTP Sent", variant: "dark" });
          setDataToSend({
            loggedInUserId: data.data.userId,
            phoneNumber: values.phoneNumber,
            phoneCode: values.phoneCode,
            companyName: values.companyName,
            email: values.email,
          });
          localStorage.setItem("openModal", true);
          disableLoading();
        })
        .catch((err) => {
          setSubmitting(false);
          Toaster({ message: "User already registered", variant: "dark" });
          disableLoading();
        });

      // register(values)
      //   .then(({ data }) => {
      //     Toaster({
      //       message: "Thanks your account has been successfully created.",
      //       variant: "success",
      //     });
      //     localStorage.setItem("openModal", true);
      //     login(values)
      //       .then(({ data }) => {
      //         props.login(data?.data);
      //         disableLoading();
      //         setSubmitting(false);
      //       })
      //       .catch((err) => {
      //         setSubmitting(false);
      //         setStatus(
      //           intl.formatMessage({
      //             id: "AUTH.VALIDATION.INVALID_LOGIN",
      //           })
      //         );
      //         disableLoading();
      //       });
      //   })
      //   .catch((err) => {
      //     setStatus(
      //       err.response.data.message === "User Already Exsit"
      //         ? "This number is already registered with us, please sign in"
      //         : err.response.data.message
      //     );
      //     disableLoading();
      //   });
    },
  });
  console.log("formik error", formik.errors);
  //------------------------------- Google Signin Setup ---------------------------

  useEffect(() => {
    const googleSignInScript = document.createElement("script");
    googleSignInScript.src = "https://accounts.google.com/gsi/client";
    googleSignInScript.async = true;
    window.document.body.appendChild(googleSignInScript);
  }, []);

  useEffect(() => {
    credential && enableLoading();
    credential &&
      googleSignInorLogin({ token: credential }).then(({ data }) => {
        localStorage.setItem("openModal", true);
        if (data.data.status === "Login") {
          props.login(data?.data);
        }
        disableLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential]);

  // ----------------------------------End of Google Signin Setup- ----------------------------

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {/* {loading && <div><ComponentLoader /></div>} */}
      <div className=" mb-0 mb-lg-0">
        <h3 className="font-size-h3 text-center mb-8">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Enter your details to create an Account
        </p> */}
      </div>

      {!isOtp && (
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}

          {/* {


        {/* begin: Email */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Primary Email"
            disabled={registerData?.loginEmail ? true : false}
            type="email"
            className={`form-control ${getInputClasses(
              "loginEmail"
            )}`}
            name="loginEmail"
            {...formik.getFieldProps("loginEmail")}
            value={formik.values.loginEmail || ""}
          />
          {formik.touched.loginEmail && formik.errors.loginEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.loginEmail}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: Email */}

          {/* begin: phoneNumber */}
          {phoneStatus && (
            <div className="form-group fv-plugins-icon-container">
              <PhoneInput
                enableAreaCodes={true}
                inputProps={{
                  name: "number",
                  required: true,
                }}
                value={
                  formik.values["number"]
                    ? formik.values["phoneCode"] + formik.values["number"]
                    : null
                }
                placeholder="Enter phone number"
                country={"in"}
                preferredCountries={["ae", "sa"]}
                countryCodeEditable={false}
                // disabled={registerData?.phoneNumber ? true : false}
                enableSearch={true}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                }}
                onChange={(value, countryData) => {
                  let phoneCode = `+${countryData.dialCode}`;
                  let phoneNumber = value.replace(countryData.dialCode, "");
                  formik.setFieldValue("phoneNumber", phoneNumber);
                  formik.setFieldValue("phoneCode", phoneCode);
                }}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.phoneNumber}
                  </div>
                </div>
              ) : null}
              <div className="fv-plugins-message-container">
                <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted required">
                  We respect your privacy and we will never share it with others
                  or spam.
                </p>
              </div>
            </div>
          )}
          {/* end: countryCode */}

          {/* begin: Work Email */}
          {!phoneStatus && (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Work Email"
                type="email"
                className={`form-control ${getInputClasses("email")}`}
                name="email"
                {...formik.getFieldProps("email")}
                value={formik.values.email || ""}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
              <div className="fv-plugins-message-container">
                <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted required">
                  We respect your privacy and we will never share it with others
                  or spam.
                </p>
              </div>
            </div>
          )}
          {/* end: Work Email */}

          {/* begin: firstname */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First name"
            type="text"
            className={`form-control ${getInputClasses(
              "firstName"
            )}`}
            name="firstName"
            {...formik.getFieldProps("firstName")}
            value={formik.values.firstName || ""}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: firstname */}

          {/* begin: lastname */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last name"
            type="text"
            className={`form-control ${getInputClasses(
              "lastName"
            )}`}
            name="lastName"
            {...formik.getFieldProps("lastName")}
            value={formik.values.lastName || ""}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: lastname */}

          {/* begin: companyName */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Company Name"
              type="text"
              className={`form-control ${getInputClasses("companyName")}`}
              name="companyName"
              {...formik.getFieldProps("companyName")}
              value={formik.values.companyName || ""}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.companyName}</div>
              </div>
            ) : null}
            <div className="fv-plugins-message-container">
              <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted required">
                Your name, nick name or name appears on any id card.
              </p>
            </div>
          </div>
          {/* end: companyName */}

          {/* begin: Password */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Create Password"
            type="password"
            className={`form-control ${getInputClasses("password")}`}
            name="password"
            {...formik.getFieldProps("password")}
            value={formik.values.password || ""}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: Password */}

          {/* begin: Confirm Password */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
            value={formik.values.changepassword || ""}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div> */}
          {/* end: Confirm Password */}

          <div className=" d-flex flex-wrap d-flex justify-content-center mb-3">
            {/* <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary  mx-4"
            >
              Cancel
            </button>
          </Link> */}
            <button
              type="submit"
              disabled={
                formik.isSubmitting || !formik.isValid
                // !formik.values.acceptTerms
              }
              className="btn btn_secondary w-100 h-45px"
            >
              {!loading ? (
                <span>
                  <b>Register for free</b>
                </span>
              ) : (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
              {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
            </button>
          </div>
        </form>
      )}

      {isOtp && (
        <div className="fv-plugins-icon-container">
          {error.length > 1 ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {"You have entered wrong otp"}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <input
            placeholder="Enter otp"
            type="text"
            className={'form-control '}
            name="otp"
            onChange={(e) => {
              setOtp(e.target.value)
              setError('')
            }}
          // {...formik.getFieldProps("email")}
          // value={formik.values.email || ""}
          /> */}
          <div className="d-flex justify-content-between mx-6 mt-8 form-group">
            <input
              name="otp1"
              type="text"
              autoComplete="off"
              className="otpInput"
              id="otp1"
              // value={this.state.otp1}
              // onKeyPress={this.keyPressed}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp1("")
                  : setOtp1(e.target.value)
              }
              tabIndex="1"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "", "otp1", "otp2")}
              value={otp1}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              className="otpInput"
              id="otp2"
              // value={this.state.otp2}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp2("")
                  : setOtp2(e.target.value)
              }
              tabIndex="2"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp1", "otp2", "otp3")}
              value={otp2}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              className="otpInput"
              // value={this.state.otp3}
              id="otp3"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp3("")
                  : setOtp3(e.target.value)
              }
              tabIndex="3"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp2", "otp3", "otp4")}
              value={otp3}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              className="otpInput"
              // value={this.state.otp4}
              id="otp4"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp4("")
                  : setOtp4(e.target.value)
              }
              tabIndex="4"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp3", "otp4", "")}
              value={otp4}
            />
          </div>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block-info">
              We sent a 4 digit code to{" "}
              {phoneStatus ? (
                <b>
                  {formik.values.phoneCode + "-" + formik.values.phoneNumber}{" "}
                </b>
              ) : (
                <b>{formik.values.email} </b>
              )}
              via {phoneStatus ? "SMS" : "EMAIL"} to verify your{" "}
              {phoneStatus ? "number" : "email id"}
            </div>
          </div>
          <div className="mb-3 mt-5 d-flex flex-wrap align-items-center justify-content-between">
            <div className="col-md-12 text-center px-0">
              <button
                id="kt_login_signin_submit"
                // type="submit"
                // disabled={formik.isSubmitting}
                disabled={
                  loading ||
                  otp1.length < 1 ||
                  otp2.length < 1 ||
                  otp3.length < 1 ||
                  otp4.length < 1
                }
                className={`btn btn_secondary w-100 h-45px`}
                onClick={() => {
                  let objToSend = {
                    registrationCode: otp1 + otp2 + otp3 + otp4,
                    ...dataToSend,
                  };
                  verifyOtp(objToSend);
                }}
              >
                {<span>Verify Otp</span>}
                {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
          {seconds >= 1 && (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer`}
              rel="noopener noreferrer"
              // href="#"
            >
              Resend otp in {"0" + minutesTobeShown + " : " + secondsToBeShown}
            </a>
          )}
          {typeof seconds === "string" ? (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer ${
                disableResendOtp ? "text-muted" : ""
              }`}
              rel="noopener noreferrer"
              // href="#"
              onClick={() => {
                setSeconds(30);
                setDisableResendOtp(true);
                sendPhoneOtpForRegistration({
                  ...phoneDetails,
                }).then(() => {
                  Toaster({ message: "OTP Sent", variant: "dark" });
                  setDisableResendOtp(false);
                });
              }}
            >
              Click here to resend OTP
            </a>
          ) : null}
        </div>
      )}

      {/* -----------------Google Signin Call back function--------------- */}

      {
        (window.loginCallBackFn = (resp, resp1) => {
          setCredential(resp.credential);
        })
      }
      {/* -----------------End of Google Signin Call back function--------------- */}

      {/* ----------------Google Siginin Button */}
      <p className="d-flex justify-content-center text-muted">Or</p>
      <div className="form-group row justify-content-center">
        <div className="d-flex justify-content-center mr-3">
          <div
            id="g_id_onload"
            data-client_id={process.env.REACT_APP_GOOGLE_SIGN_UP_CLIENT_ID}
            data-context="signin"
            data-ux_mode="popup"
            data-callback="loginCallBackFn"
            data-auto_prompt="false"
          ></div>

          <div
            class="g_id_signin w-auto mx-auto"
            data-type="icon"
            data-shape="rectangular"
            data-theme="outline"
            data-text="continue_with"
            data-size="large"
            data-logo_alignment="left"
          ></div>
          {/*---------------- End of Google  Siginin Button*/}
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            setPhoneStatus(!phoneStatus);
          }}
        >
          {phoneStatus ? (
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Frame-1-4.svg")}
              className="h-150 align-self-center"
            ></SVG>
          ) : (
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Frame-1-5.svg")}
              className="h-150 align-self-center"
            ></SVG>
          )}
        </div>
      </div>
      {/* begin: Terms and Conditions */}
      <div className="form-group d-flex justify-content-start mt-7 mb-0">
        {/* <label className="checkbox"> */}
        {/* <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            /> */}
        {/* <span className="d-flex justify-content-center "></span> */}
        <p className="ml-0 terms_text font-weight-bold font-size-sm">
          By creating an account, you agree to our
          <a
            // to="https://1000x.club/legal.html"
            target="_blank"
            className="ml-1 mr-1"
            rel="noopener noreferrer"
            // href="https://1000x.club/legal.html"
            onClick={() => window.open(`https://1000x.club/legal.html`)}
          >
            Terms of Use and Privacy Policy.
          </a>
        </p>
        {/* </label> */}
        {/* {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null} */}
      </div>
      {/* end: Terms and Conditions */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
