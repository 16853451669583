import axios from "axios";

export const GET_GALLERY_CONTENTS =
  "v1/brand/campaign/application/contents/list";
export const GET_DOWNLOAD_MEDIA_URL =
  "v1/brand/campaign/application/contents/generateMediaDownloadUrl";
export const GET_DOWNLOAD_REFERENCE_URL =
  "v1/brand/campaigns/generateMediaDownloadUrl";

export function getGalleryContents(params) {
  return axios.post(GET_GALLERY_CONTENTS, {
    ...params,
    frontendAction: "brand.campaign.application.contents.list",
    trackingPriority: 0,
  });
}

export function getMediaDownloadURL(params) {
  return axios.post(GET_DOWNLOAD_MEDIA_URL, {
    ...params,
    frontendAction:
      "brand.campaign.application.contents.generateMediaDownloadUrl",
    trackingPriority: 0,
  });
}

export function getReferenceDownloadURL(params) {
  return axios.post(GET_DOWNLOAD_REFERENCE_URL, {
    ...params,
    frontendAction: "brand.campaigns.generateMediaDownloadUrl",
    trackingPriority: 0,
  });
}
