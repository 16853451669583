import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  medias: null,
  mediaURL: "",
  contentMediaUrl: "",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    mediaFetched: (state, action) => {
      let { medias, totalCount, totalPages } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.medias = medias;
      state.totalCount = totalCount;
      state.totalPages = totalPages
    },

    downloadMediaUrlFetched: (state, action) => {
      let { url, contentMediaUrl } = action.payload;
      state.mediaURL = url;
      state.contentMediaUrl = contentMediaUrl;
      state.listLoading = false;
      state.error = null;
    },
  },
});
