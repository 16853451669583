import { createSlice } from "@reduxjs/toolkit";
import { isArray } from "lodash";

export const notificationModuleInitialState = {
  list: true,
  actionsLoading: false,
  notificationsList: [],
  totalCount: 0,
  totalPages: 1,
  applicationDetails: {},
  isApiInprogressFlag: false,
  unreadNotificationCount: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const notificationSlice = createSlice({
  name: "notificationModule",
  initialState: notificationModuleInitialState,
  reducers: {
    toggleIsApiInprogressFlag: (state, action) => {
      state.isApiInprogressFlag = Boolean(action.payload);
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list = false;
      } else {
        state.actionsLoading = false;
      }
    },

    notificationsFetched: (state, action) => {
      let { notificationsList, totalCount } = action.payload;
      state.list = false;
      state.error = null;
      state.notificationsList = notificationsList.map((data) => data.metaData );
      state.totalCount = totalCount;
    },

    unreadNotificationCountFetched: (state, action) => {
      // state.list = false;
      // state.error = null;
      state.unreadNotificationCount = action.payload;
    },

    updateIncomingUnreadNotificationCount: (state, action) => {
      state.unreadNotificationCount = action.payload;
    },
    
    clearNotificationSlice: (state, action) => {
      state.notificationsList = []
    },
  },
});
