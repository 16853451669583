import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  variant: "info",
  flag: false,
  type: "",
  status: "",
};


export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar(state, action) {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
      state.type= action.payload.type;
      state.status= action.payload.status;
      state.flag =  action.payload.flag;
    },
    clearSnackbar(state, action) {
      state.message = "";
      state.variant = "";
      state.flag = false;
      state.type= "";
      state.status= "";
    },
  }
});
