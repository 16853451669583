import axios from "axios";

export const GET_SIGNED_URL = "v1/image/signedUrl";
export const GET_CREATOR_PROFILE = "v1/brand/profile/getProfileDetails";
export const UPDATE_CREATOR_PROFILE = "v1/brand/profile/updateProfile";
export const CHANGE_PASSWORD = "v1/brand/profile/changePassword";
export const GET_BRAND_RATING = "v1/brand/rating/review/getBrandRating";
export const UPDATE_BRAND_EMAIL = "v1/brand/profile/verifyUserEmail";
export const VERIFY_BRAND_EMAIL = "v1/brand/profile/updateEmail";
export const LIST_CREATOR_USERS = "v1/brand/campaigns/listCreators";
export const LIST_SOCIAL_MEDIA_BRANDS =
  "v1/brand/campaign/socialMedia/listBrands";

// GET =>  get signed Url
export function getSignedUrl(signedUrl) {
  return axios.post(GET_SIGNED_URL, {
    ...signedUrl,
    frontendAction: "ImageSignedUrl",
    trackingPriority: 0,
  });
}

//file upload to s3
export function signedUpload(url, file) {
  let config = {
    headers: {
      "Content-type": file.type,
    },
  };
  return axios.put(url, file, config);
}

export function getCreatorProfile(params) {
  return axios.post(GET_CREATOR_PROFILE, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.profile.getProfileDetails",
  });
}

export function updateCreatorProfile(params) {
  return axios.post(UPDATE_CREATOR_PROFILE, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.profile.updateProfile",
  });
}

export function changePassword(params) {
  return axios.post(CHANGE_PASSWORD, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.profile.changePassword",
  });
}

export function getBrandRating(params) {
  return axios.post(GET_BRAND_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.rating.review.getBrandRating",
  });
}

export function updateEmail(params) {
  return axios.post(UPDATE_BRAND_EMAIL, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.profile.verifyUserEmail",
  });
}

export function verifyBrandEmail(params) {
  return axios.post(VERIFY_BRAND_EMAIL, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.profile.updateEmail",
  });
}

export function listCreatorUsers(params) {
  return axios.post(LIST_CREATOR_USERS, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.campaigns.listCreators",
  });
}

export function listSocialMediaBrands(params) {
  return axios.post(LIST_SOCIAL_MEDIA_BRANDS, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.campaign.socialMedia.listBrands",
  });
}
