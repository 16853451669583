import React from "react";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export const getAgeCategoryTotext = (data) => {
  switch (data) {
    case "MILLENIALS_26_41":
      return "Age varies from 26-41";
    case "GENX_42_57":
      return "Age varies from 42-57";
    case "ANY":
      return "Any";
    case "GENZ_10_25":
      return "Age varies from 10-25";
    case null:
      return "Not mentioned";
    default:
      return data;
  }
};

export const getCampaignType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const getConvertedApplicationStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "Pending Approval";
    case "REJECTED_BY_BRAND":
      return "Rejected";
    case "APPROVED_BY_BRAND":
      return "Approved";
    default:
      return "Not Applied";
  }
};

export const getConvertedContentStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "Waiting for Approval";
    case "REJECTED_BY_BRAND":
      return "Rejected";
    case "APPROVED_BY_BRAND":
      return "Approved";
    case "DRAFT":
      return "Draft";

    case "RESUBMIT_CONTENT":
      return "Rework Required";

    case "SOCIAL_MEDIA_URL_REQUESTED":
      return "Social Media Url Requested";

    case "URL_SUBMITTED_BY_CREATOR":
      return "URL Submitted";

    case "URL_APPROVED_BY_BRAND":
      return "URL Approved";

    default:
      return "No Status";
  }
};

export const getTargetGenderToText = (data) => {
  switch (data) {
    case "MALE":
      return "Male";
    case "FEMALE":
      return "Female";
    case "ANY":
      return "Any";
    case null:
      return "Not mentioned";
    default:
      return data;
  }
};

export const getFeaturesToText = (data) => {
  switch (data) {
    case "TECHNOLOGY":
      return "Technology";
    case "SPORTS":
      return "Sports";
    case "ENTERTAINMENT":
      return "Entertainement";
    case "MUSIC":
      return "Music";
    case "FASHION":
      return "Fashion";
    case "FOOD":
      return "Food";
    case "ART":
      return "Art";
    // postImageStyle
    case "PRODUCT_PHOTOGRAPHY":
      return "Product Photography";
    case "LIFESTYLE_PRODUCT_IN_ACTION":
      return "Lifestyle product in action";
    case "PORTRAIT_STYLE_PHOTOGRAPHY":
      return "Potrait style photography";
    // postVideoStyle
    case "ACTION_PRODUCT_IN_USE_VIDEO":
      return "Action product in use video";
    case "UNBOXING_EXPLANATION_VIDEO":
      return "Unboxing explanation video";
    case "PRODUCT_REVIEW_TESTIMONIAL_VIDEO":
      return "Product review testimonial video";
    // CAMPAIGN TYPE
    case "SOCIAL_POST":
      return "Socail Media Campaign";
    case "PHOTOGRAPHY":
      return "Photography Campaign";
    case "VIDEOGRAPHY":
      return "Videography Campaign";
    // POST TYPE
    case "VIDEO_POST":
      return "Video Post";
    case "IMAGE_POST":
      return "Image Post";
    default:
      return data;
  }
};

export const getPostType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const getCurrencySymbol = (data) => {
  switch (data) {
    case "USD":
      return "$ ";
    case "INR":
      return "₹ ";
    case "AED":
        return "AED";
    default:
      return "₹ ";
  }
};

export const getCurrencyImage = (data) => {
  switch (data) {
    case "USD":
      return <img class="mt-n1 mr-1" src="/media/custom/Icon_dollar.svg" />;
    case "INR":
      return <img class="mt-n1" src="/media/custom/Icon_rupee_sign.svg" />;
    case "AED":
        return <span class="mt-n1">AED</span>;
    default:
      return <img class="mt-n1" src="/media/custom/Icon_rupee_sign.svg" />;
  }
};

export const campaignType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const getPlatform = (data) => {
  switch (data) {
    case "INSTAGRAM":
      return "Instagram";
    case "FACEBOOK":
      return "Facebook";
    case "TIKTOK":
      return "Tik tok";
    case "YOUTUBE":
      return "Youtube";
    case "AMAZONE":
      return "Amazone";
    case "GENERAL_USE":
      return "General Use";
    default:
      return "not mentioned";
  }
};

export const getVideoLength = (data) => {
  switch (data) {
    case "UP_TO_15_SECONDS":
      return " Up to 15 seconds";
    case "15_TO_30_SECONDS":
      return " 15 to 30 seconds";
    case "30_TO_60_SECONDS":
      return " 30 to 60 seconds";
    case "MORE_THAN_60_SECONDS":
      return " More than 60 seconds";
    default:
      return data;
  }
};

export const getReferenceMediaCount = (campaign) => {
  let videoCount =
    +Number(campaign.styleSelectionActionProductInUseCount);
  let imageCount =
    +Number(campaign.styleSelectionGraphicDesignCount);
  let documentCount =
    +Number(campaign.styleSelectionPortraitStylePhotographyCount);
  let audioCount =
    +Number(campaign.styleSelectionLifestyleProductInActionCount);
  let otherCount =
    +Number(campaign.styleSelectionPromotionalVideoCount);
  
  return { videoCount, imageCount, documentCount, audioCount, otherCount};
};

export const getFileType = (type) => {
  let splited = type.split("/");
  switch (splited[0]) {
    case "image":
      return "M_IMAGE";
    case "video":
      return "M_VIDEO";
    case "application":
      return "M_FILE";
    default:
      return "M_TEXT";
  }
};

export const initialFiles = {
  actionProductInUseFiles: {},
  graphicDesignFiles: {},
  lifestyleProductInActionFiles: {},
  portraitStylePhotographyFiles: {},
  productPhotographyFiles: {},
  productReviewTestimonialVideoFiles: {},
  promotionalVideoFiles: {},
  unboxingExplanationVideoFiles: {},
};

export const getFileTypeFromExtension = (url) => {
  let extension = url?.split(".").pop();

  let videoExtensions = [
    "mp4",
    "m4p",
    "mpeg",
    "mpg",
    "flv",
    "3gp",
    "webm",
    "mkv",
    "flv",
    "ogg",
    "ogv",
    "avi",
    "mov",
    "wmv",
    "",
  ];
  let imageExtensions = [
    "gif",
    "jpg",
    "jpeg",
    "png",
    "svg",
    "webp",
    "ico",
    "bmp",
    "jfif",
  ];
  let docuementExtensions = ["pdf", "txt", "doc", "docx", "html", "htm", "odt"];

  if (videoExtensions?.includes(extension)) {
    return "video";
  } else if (imageExtensions?.includes(extension)) {
    return "image";
  } else if (docuementExtensions?.includes(extension)) {
    return "document";
  } else {
    return null;
  }
};

export const getConvertedRejectReason = (reason) => {
  switch (reason) {
    case "POOR_CONTENT_QUALITY":
      return "Poor Content Quality";
    case "DELAYED_DELIVERY":
      return "Delayed Delivery";
    case "DEVIATION_FROM_GUIDELINES":
      return "Deviation From Guidelines";
    default:
      return null;
  }
};

export const getConvertedPayMentStatus = (status) => {
  switch (status) {
    case "CAPTURED":
      return { status: "Payment Success", color: "text-success" };

    case "AUTHORIZED":
      return { status: "Payment Authorized", color: "text-warning" };

    case "FAILED":
      return { status: "Payment Reversed", color: "text-danger" };

    case "PENDING":
      return { status: "Payment Pending", color: "text-warning" };

    default:
      return { status: "Payment Pending", color: "text-warning" };
  }
};

export const getConvertedPayoutStatus = (status) => {
  switch (status) {
    case "PAYOUT_INITIATED":
      return { status: "Payment Initiated", color: "text-warning" };
    case "PAYOUT_PROCESSED":
      return { status: "Payment Success", color: "text-success" };
    case "PAYOUT_REVERSED":
      return { status: "Payment Reversed", color: "text-danger" };
    case "PAYOUT_QUEUED":
      return { status: "Payment Queued", color: "text-warning" };
    case "PAYOUT_REJECTED":
      return { status: "Payment Rejected", color: "text-danger" };
    case "PAYOUT_PENDING":
      return { status: "Payment Pending", color: "text-warning" };
    default:
      return { status: "Payment Pending", color: "text-warning" };
  }
};

export const getMediaPreview = (content, paymentStatus, rewardType) => {
  if (content?.postType === "VIDEO_POST") {
    return "url(/media/custom/video-icon.png)";
  } else if (content?.postType === "IMAGE_POST") {
    if (content?.contentMediaUrl.endsWith(".pdf")) {
      return "url(/media/dummyImages/PDF_no_prview.png)";
    } else {
      return rewardType !== "CASH" || paymentStatus === "CAPTURED"
        ? `url(
        ${process.env.REACT_APP_AWS_S3_BASE_URL + content?.contentMediaUrl}
        )`
        : `url(
          ${process.env.REACT_APP_AWS_S3_BASE_URL +
            content?.contentMediaUrl}_watermarkedThumbnailImage 
          )`;
    }
  }
};

export function DateFormatter(cellContent) {
  let formattedDate = "";
  if (cellContent) {
    let date = parseISO(cellContent);
    formattedDate = format(date, "do MMM yyyy").toString();
  }
  return (
    <span>
      {formattedDate.includes("th") && formattedDate.replace("th", "")}
      {formattedDate.includes("st") && formattedDate.replace("st", "")}
      {formattedDate.includes("nd") && formattedDate.replace("nd", "")}
      {formattedDate.includes("rd") && formattedDate.replace("rd", "")}
    </span>
  );
}

export const getColor = (data) => {
  switch (data) {
    case "TECHNOLOGY":
      return "#1BC5BD";
    case "ART":
      return "green";
    case "SPORTS":
      return "yellow";
    case "ENTERTAINMENT":
      return "#F64E60";
    case "FASHION":
      return "primary";
    case "white":
      return "warning";
    case "violet":
      return "warning";
    case "#FEF9A7":
      return "warning";
    case "BEVERAGES":
      return "#C70A80";
    case "E-COMMERCE":
      return "#A5BECC";
    case "ELECTRONICS":
      return "#EC9B3B";
    case "FINANCE":
      return "#afff7b";
    case "FITNESS":
      return "#14C38E";
    case "HEALTHCARE":
      return "#EEB0B0";
    case "HOMEAPPLIANCES":
      return "#F9CEEE";
    case "MEDIA":
      return "#82954B";
    case "PET":
      return "#E4AEC5";
    case "RESTAURANT":
      return "#DEA057";
    case "RETAIL":
      return "#FFA800";
    case "TRAVEL":
      return "#1BC5BD";

    default:
      return "#ffffff";
  }
};

export const getDownloadButton = (rewardType, contentStatus, paymentStatus) => {
  const possibleContentStatus = [
    "APPROVED_BY_BRAND",
    "SOCIAL_MEDIA_URL_REQUESTED",
    "URL_SUBMITTED_BY_CREATOR",
    "URL_APPROVED_BY_BRAND",
  ];

  if (rewardType === "CASH") {
    if (
      possibleContentStatus?.includes(contentStatus) &&
      paymentStatus === "CAPTURED"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getGlobalCashDiscountOffer = (userId) => {
  return 15;
}

export const productCategoryName = (productCategory) => {
  if (productCategory === "ACCOUNTINGORBOOKKEEPING") {
    return "Accounting or bookkeeping";
  } else if (productCategory === "AGRICULTUREINDUSTRY") {
    return "Agriculture Industry";
  } else if (productCategory === "AGRITECH") {
    return "Agritech";
  } else if (productCategory === "ALCOHOL") {
    return "Alcohol";
  } else if (productCategory === "ANIMALS") {
    return "Animals";
  } else if (productCategory === "APPAREL") {
    return "Apparel";
  } else if (productCategory === "ART") {
    return "Art";
  } else if (productCategory === "AUTOMOTIVE") {
    return "Automotive";
  } else if (productCategory === "ADROPPINGSTORE") {
    return "A dropping store";
  } else if (productCategory === "APRINTONDEMAND") {
    return "A print-on-demand store";
  } else if (productCategory === "BABYPRODUCTS") {
    return "Baby products";
  } else if (productCategory === "BEAUTY") {
    return "Beauty";
  } else if (productCategory === "BEVERAGES") {
    return "Beverages";
  } else if (productCategory === "BUSINESSSERVICES") {
    return "Business services";
  } else if (productCategory === "BUSINESSCOACHING") {
    return "Business coaching";
  } else if (productCategory === "BUYBULKPRODUCTS") {
    return "Buy Products in Bulk to sell";
  } else if (productCategory === "CLOUDKITCHENS") {
    return "Cloud Kitchens";
  } else if (productCategory === "COLLEGECONSULTING") {
    return "College consulting";
  } else if (productCategory === "CONSUMERPACKEDGOODS") {
    return "Consumer Packaged Goods";
  } else if (productCategory === "CYBERSECURITYANDITCONSULTING") {
    return "Cybersecurity and IT consulting";
  } else if (productCategory === "DECOR") {
    return "Decor";
  } else if (productCategory === "DESIGNING") {
    return "Designing";
  } else if (productCategory === "DIGITALADVERTISING") {
    return "Digital advertising";
  } else if (productCategory === "DIVERSIFIED") {
    return "Diversified";
  } else if (productCategory === "DOGWALKING") {
    return "Dog-walking";
  } else if (productCategory === "DROPSHIPPING") {
    return "Dropshipping";
  } else if (productCategory === "EDITING") {
    return "Editing";
  } else if (productCategory === "EDUCATION") {
    return "Education";
  } else if (productCategory === "E-BOOKWRITING") {
    return "e-Book writing";
  } else if (productCategory === "E-COMMERCE") {
    return "E-commerce";
  } else if (productCategory === "E-COMMERCESTORE") {
    return "E-commerce store";
  } else if (productCategory === "ELECTRONICS") {
    return "Electronics";
  } else if (productCategory === "ENTERTAINMENT") {
    return "Entertaiment";
  } else if (productCategory === "FASHION") {
    return "Fashion";
  } else if (productCategory === "FINANCE") {
    return "Financial Services";
  } else if (productCategory === "FINTECH") {
    return "Fintech";
  } else if (productCategory === "FITNESS") {
    return "Fitness";
  } else if (productCategory === "FOOD") {
    return "Food";
  } else if (productCategory === "FREELANCECODING&WEBDESIGN") {
    return "Freelance coding and web design";
  } else if (productCategory === "FREELANCEDESIGN") {
    return "Freelance designing";
  } else if (productCategory === "FREELANCERESEARCH") {
    return "Freelance researching";
  } else if (productCategory === "FREELANCEWRITING") {
    return "Freelance writing";
  } else if (productCategory === "HEALTHCARE") {
    return "Health Care";
  } else if (productCategory === "HEAVYEQUIPEMENT") {
    return "Heavy Equipment";
  } else if (productCategory === "HOLIDAY") {
    return "Holiday";
  } else if (productCategory === "HOMEAPPLIANCES") {
    return "Home Appliances";
  } else if (productCategory === "HOUSECLEANING") {
    return "House cleaning";
  } else if (productCategory === "HUMANRESOURCESERVICE") {
    return "Human Resources Service";
  } else if (productCategory === "INFORMATIONTECH") {
    return "Information technology";
  } else if (productCategory === "INTERNETDOMAINSELLING") {
    return "Internet domain selling";
  } else if (productCategory === "LEISURE") {
    return "Leisure";
  } else if (productCategory === "LIFECOACHING") {
    return "Life coaching";
  } else if (productCategory === "LOGISTICS") {
    return "Logistics";
  } else if (productCategory === "LUXURY") {
    return "Luxury";
  } else if (productCategory === "MARKETING") {
    return "Marketing";
  } else if (productCategory === "MARKETINGCONSULTING") {
    return "Marketing consulting";
  } else if (productCategory === "MEDIA") {
    return "Media";
  } else if (productCategory === "MEDICALSERVICE") {
    return "Medical Service";
  } else if (productCategory === "MEDTECH") {
    return "Medtech";
  } else if (productCategory === "OILANDGAS") {
    return "Oil and Gas";
  } else if (productCategory === "ONLINEFITNESSTRAINER") {
    return "Online fitness trainer";
  } else if (productCategory === "ONLINEFUNDRAISINGORGRANTWRITING") {
    return "Online fundraising or grant writing";
  } else if (productCategory === "ONLINEPERSONALASSISTANT") {
    return "Online personal assistant";
  } else if (productCategory === "ONLINERECRUITING") {
    return "Online recruiting";
  } else if (productCategory === "ONLINETHERAPY") {
    return "Online therapy";
  } else if (productCategory === "PET") {
    return "Pet";
  } else if (productCategory === "PERSONALTRAINING") {
    return "Personal training";
  } else if (productCategory === "PHOTOGRAPHY") {
    return "Photography";
  } else if (productCategory === "PODCASTING") {
    return "Podcasting";
  } else if (productCategory === "PRCONSULTING") {
    return "PR consulting";
  } else if (productCategory === "PRODUCTIZESERVICEOREXPERTISE") {
    return "Productize your service or expertise";
  } else if (productCategory === "RESTAURANT") {
    return "Restaurant";
  } else if (productCategory === "RESUMEWRITINGANDCAREERCOACHING") {
    return "Resume writing and career coaching";
  } else if (productCategory === "RETAIL") {
    return "Retail";
  } else if (productCategory === "SELLHOMEMADEPRODUCTS") {
    return "Sell homemade products you make yourself";
  } else if (productCategory === "SELLSERVICEOREXPERTISE") {
    return "Sell  service or expertise";
  } else if (productCategory === "SEOCONSULTING") {
    return "SEO consulting";
  } else if (productCategory === "SOCIALMEDIAMARKETING") {
    return "Social media marketing";
  } else if (productCategory === "SOFTWARE&APPDEV") {
    return "Software and app development";
  } else if (productCategory === "SPORTS") {
    return "Sports";
  } else if (productCategory === "STOCKORFOREIGNCURRENCYTRADING") {
    return "Stock or foreign currency trading";
  } else if (productCategory === "TECHNOLOGY") {
    return "Technology";
  } else if (productCategory === "TELECOM") {
    return "Telecom";
  } else if (productCategory === "TELECOMMUNICATION") {
    return "Telecommunication";
  } else if (productCategory === "TELEMARKETING") {
    return "Telemarketing";
  } else if (productCategory === "TOBACCO") {
    return "Tobacco";
  } else if (productCategory === "TRANSCRIPTION") {
    return "Transcription";
  } else if (productCategory === "TRANSPORTATION") {
    return "Transportation";
  } else if (productCategory === "TRANSLATION") {
    return "Translation";
  } else if (productCategory === "TRAVEL") {
    return "Travel";
  } else if (productCategory === "TRAVELPLANNING") {
    return "Travel planning";
  } else if (productCategory === "TUTORING") {
    return "Tutoring";
  } else if (productCategory === "VIDEOPRODUCTION") {
    return "Video production";
  } else if (productCategory === "VIRTUALASSISTANCE") {
    return "Virtual assistance";
  } else if (productCategory === "VIRTUALTHRIFTSHOP") {
    return "Virtual thrift shop";
  } else if (productCategory === "WRITINGABLOG") {
    return "Writing a blog";
  } else if (productCategory === "YOUTUBE") {
    return "YouTube channel";
  } else if (productCategory === "OTHERS") {
    return "Others";
  }
};

export const checkCampaignContentRequest = (campaign) => {
  var retFlag = campaign?.styleSelectionGraphicDesignCount > 0 || //Image Count
  campaign?.styleSelectionActionProductInUseCount > 0 || //Video Count
  campaign?.styleSelectionPortraitStylePhotographyCount > 0 || //Document Count
  campaign?.styleSelectionLifestyleProductInActionCount > 0 || //Audio Count
  campaign?.styleSelectionPromotionalVideoCount > 0 //Other Count
    ? true:false
  return retFlag;
}

export const checkMediaContentRequest = (mediaCount) => {
  var retFlag = mediaCount?.imageCount > 0 ||
  mediaCount?.videoCount > 0 ||
  mediaCount?.documentCount > 0 ||
  mediaCount?.audioCount > 0 ||
  mediaCount?.otherCount > 0
    ? true:false
  return retFlag;
}
