import * as requestFromServer from "./commonCrud";
import { commonSlice, callTypes } from "./commonSlice";
import { Toaster } from "../../components/Toaster";

const { actions } = commonSlice;

export const getSignedUrl = (signedUrlDataForCreation) => (dispatch) => {
  let { file, ...rest } = signedUrlDataForCreation;
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSignedUrl({ ...rest })
    .then((response) => {
      requestFromServer
        .signedUpload(response?.data?.data.signed_request, file)
        .then((res) => {
          dispatch(actions.signedUrlFetched(res.statusText));
          dispatch(actions.callSuccess({ callType: callTypes.action }));
        })
        .catch((err) => {
          dispatch(actions.catchError({ callType: callTypes.action }));
          // console.log("err........", err);
        });
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.action }));
      return error;
    });
};

export const clearSignedUrlFetched = () => (dispatch) => {
  dispatch(actions.signedUrlFetched(""));
};

export const getCreatorProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreatorProfile(queryParams)
    .then((response) => {
      const profileToEdit = response.data.data;
      let firstName = response.data.data.firstName
        ? response.data.data.firstName
        : "";
      let lastName = response.data.data.lastName
        ? response.data.data.lastName
        : "";
      profileToEdit.fullName = `${firstName}` + " " + `${lastName}`;
      if (profileToEdit?.brandType !== "") {
        profileToEdit.brandType = {
          value: profileToEdit?.brandType,
          label: profileToEdit?.brandType,
        };
      }
      dispatch(actions.creatorProfileFetched(profileToEdit));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateCreatorProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCreatorProfile(queryParams)
    .then((response) => {
      Toaster({ message: "Profile updated successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const startGetTokenDataLoader = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.web }));
};

export const endGetTokenDataLoader = (queryParams) => (dispatch) => {
  dispatch(actions.callSuccess({ callType: callTypes.web }));
};

export const changePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePassword(queryParams)
    .then((response) => {
      Toaster({ message: "Password changed successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSocket = (queryParams) => (dispatch) => {
  dispatch(actions.createSocket(queryParams));
};

export const getProfilePicture = (queryParams) => (dispatch) => {
  console.log("queryParams", queryParams);
  const profileToEdit = { profilePic: queryParams };
  dispatch(actions.creatorProfileFetched(profileToEdit));
};

export const getBrandRating = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBrandRating(queryParams)
    .then((response) => {
      dispatch(actions.ratingFetched(response.data.data[0]));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch rating";
      dispatch(actions.ratingFetched(0));
      // Toaster({ message: error?.response?.data?.message, variant: "error" });
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateEmail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEmail(queryParams)
    .then((response) => {
      Toaster({
        message: "Verification link send successfully",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({
        message: error?.response?.data?.data?.message,
        variant: "dark",
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const verifyEmail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyBrandEmail(queryParams)
    .then((response) => {
      Toaster({ message: "email updated successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      console.log("error", error?.response?.data);
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const viewModalInfo = (queryParams) => (dispatch) => {
  dispatch(actions.isVisibleModalInfo(queryParams));
};

export const viewSideBar = (queryParams) => (dispatch) => {
  dispatch(actions.isVisibleSideBar(queryParams));
};

export const setRightSideBar = (queryParams) => (dispatch) => {
  dispatch(actions.setRightSidebar(queryParams));
};

export const listCreatorUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .listCreatorUsers(queryParams)
    .then((response) => {
      let dataArray = [];
      let rowData = response.data.data.rows;
      rowData.map((val) => {
        let obj = {};
        let firstName = val.firstName ? val.firstName : "";
        let lastName = val.lastName ? val.lastName : "";
        obj.value = firstName + " " + lastName;
        obj.label = firstName + " " + lastName;
        obj.id = val.userId;
        dataArray.push(obj);
      });
      dispatch(actions.creatorsList(dataArray));
      return dataArray;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const listSocialMediaBrands = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .listSocialMediaBrands(queryParams)
    .then((response) => {
      let dataArray = [];
      let rowData = response.data.data.rows;
      rowData.map((val) => {
        let obj = {};
        obj.value = val.brandName;
        obj.label = val.brandName;
        obj.brandLogo = val.brandLogo;
        obj.id = val.id;
        dataArray.push(obj);
      });
      dispatch(actions.socialMediaBrandsList(dataArray));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
