import React from "react";

const OrderCard = ({ order, user }) => {
  return (
    <>
      {order && (
        <div class="w-full p-4 border-md rounded-lg mx-4 mb-4 oCard">
          <div className="oCardTop">
            <div className="oCardId">PRIMEADS_{order.id}</div>
            <img
              src="/media/stock-600x600/editPen.png"
              alt=""
              className="oCardEdit"
            />
          </div>
          <div className="oCardHero">
            <div className="oCardHeroLeft">
              <img
                src="/media/stock-600x600/profile.png"
                alt=""
                className="oCardProfile"
              />
              <div className="oCardHeroDetails">
                <span className="oCardTitle">{user.companyName}</span>
                <span className="oCardPlace">Kakkanad, Cochin</span>
              </div>
            </div>
            <div className="oCardHeroRight">
              <div className="oCardDate">Date</div>
              <div className="oCardDateValue">
                {order.startDate} - {order.endDate}
              </div>
            </div>
          </div>
          <div className="oCardBody">
            <div className="oCardBodyLeft">
              <span className="oCardBodyText">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the ...
              </span>
            </div>
            <div className="oCardBodyRight">
              <div className="oCardBudget oCardDate">Budget</div>
              <div className="oCardBudgetValue">{order.totalPrice}</div>
            </div>
          </div>
          <div className="oCardBottom">
            <div className="oCardBottomLeft">
              <div className="oCardLocations oCardDate">Locations</div>
              <div className="oCardLocationsValue">{order.locations}</div>
            </div>
            <div className="oCardBottomRight">
              <div className="oCardCancelButton">
                <img
                  src="/media/stock-600x600/cancel.png"
                  alt=""
                  className="oCardCancelImage"
                />
                <span>Cancel</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderCard;
