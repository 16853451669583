import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../campaign/_redux/campaignActions";
import { useHistory } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { nFormatter } from "../../CampaignV2/ActiveCampaign/ActiveCampaignCard";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import OrderCard from "../../../components/primeads/OrderCard";
import axios from "axios";

const useStyles = makeStyles({
  root: {},
  media: {},
});

export function MyCampaigns() {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pending, setPending] = useState(false);
  const [active, setActive] = useState(true);
  const [complete, setComplete] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const { currentState, loggedInUserId, listLoading, user } = useSelector(
    (state) => ({
      currentState: state.campaign,
      loggedInUserId: state.auth.user.userId,
      listLoading: state.campaign.listLoading,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const { totalCount, entities, totalPages, completedCampaigns } = currentState;

  // const expiryDate = new Date().toISOString();
  var date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  let expiryDate = date;

  const dispatch = useDispatch();
  useEffect(() => {
    complete
      ? dispatch(
          actions.listCompletedCampaigns({
            frontendAction: "creator.campaigns.list",
            loggedInUserId: loggedInUserId,
            page: currentPage,
            pageSize: 3,
            sort: "-updatedAt",
            sortField: "id",
            sortOrder: "asc",
            trackingPriority: 0,
            expiryFilter: expiryDate,
            query: {
              brandId: loggedInUserId,
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN"
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN",
            },
          })
        )
      : // eslint-disable-next-line react-hooks/exhaustive-deps
        // server call by queryParams
        dispatch(
          actions.listCampaigns({
            frontendAction: "creator.campaigns.list",
            loggedInUserId: loggedInUserId,
            page: currentPage,
            pageSize: 3,
            sort: "-updatedAt",
            sortField: "id",
            sortOrder: "asc",
            trackingPriority: 0,
            expiryFilter: expiryDate,
            query: {
              brandId: loggedInUserId,
              campaignStatus: active
                ? ["APPROVED_BY_ADMIN", "CAMPAIGN_APPROVED_IN_RESTRICTED_MODE"]
                : ["WAITING_FOR_APPROVAL_BY_ADMIN"],
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN",
            },
          })
        );
  }, [currentPage, active]);

  const DateFormatter = (cellContent) => {
    let formattedDate = "";
    if (cellContent) {
      let date = parseISO(cellContent);
      formattedDate = format(date, "dd MMM yyyy").toString();
    }
    return <span>{formattedDate}</span>;
  };

  useEffect(() => {
    if (complete) {
      setCampaignList(completedCampaigns);
    } else {
      setCampaignList(entities);
    }
  }, [complete, completedCampaigns, entities]);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    try {
      const getOrders = async () => {
        const res = await axios.get(
          `http://localhost:8081/api/order/${user.userId}`
        );
        setOrders(res.data);
        console.log(res.data);
      };
      getOrders();
    } catch (err) {
      console.log("error in getting orders", err);
    }
  }, []);
  return (
    <div class="card card-custom  gutter-b">
      <div class="card-header border-0 pt-0 px-4 mb-0">
        <div class="card-title font-weight-bolder mr-0 my-0">
          <div
            class="card-label cursor-pointer btn btn-default p-0 mr-0 bg-transparent border-0"
            onClick={() => {
              history.push("/orders");
            }}
          >
            My Orders
          </div>
        </div>
        <ul class="nav nav-pills nav-pills-sm nav-dark-75 card-toolbar">
          {/* <li class="nav-item">
                <button
                  class={
                    complete == true ? "btn btn-sm btn-primary" : "btn btn-sm"
                  }
                  onClick={() => {
                    setActive(false);
                    setPending(false);
                    setComplete(true)
                  }}
                >
                  Completed
                </button>
              </li> */}
          <li class="nav-item">
            <button
              class={pending == true ? "btn btn-sm btn-primary" : "btn btn-sm"}
              onClick={() => {
                setPending(true);
                setActive(false);
                setComplete(false);
              }}
            >
              Pending
            </button>
          </li>
          <li class="nav-item">
            <button
              class={active == true ? "btn btn-sm btn-primary" : "btn btn-sm"}
              onClick={() => {
                setActive(true);
                setPending(false);
                setComplete(false);
              }}
            >
              Approved
            </button>
          </li>
        </ul>
      </div>
      {orders.map((order) => (
        <OrderCard order={order} key={order.id} user={user} />
      ))}
    </div>
  );
}
