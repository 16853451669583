import * as requestFromServer from "./campaignCrud";
import { campaignSlice, callTypes } from "./campaignSlice";
import { Toaster } from "../../../components/Toaster";
// import { Toaster } from "../../../components/Toaster";

const { actions } = campaignSlice;

export const listCampaigns = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaigns(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: campaigns,
        totalPages: totalPage,
      } = response.data.data;
      dispatch(actions.campaignsFetched({ totalCount, campaigns, totalPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listCampaignTemplates = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.templateList }));
  return requestFromServer
    .getCampaignTemplates(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: templates,
        totalPages: totalPage,
      } = response.data.data;
      dispatch(actions.templatesFetched({ totalCount, templates, totalPage }));
      const templatesListForSelect = templates.map((item) => ({
        label: item?.campaignName,
        value: item?.templateData?.styleGuideLine,
        templateSummary: item?.templateData?.templateSummary,
        campaignDuration: item?.templateData?.campaignDuration,
        revisionCount: item?.templateData?.revisionCount,
      }));
      return templatesListForSelect;
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign templates";
      Toaster({ message: "Can't find campaign templates", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.templateList }));
    });
};

export const searchCampaignTemplates = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.templateList }));
  return requestFromServer
    .searchCampaignTemplates(queryParams)
    .then((response) => {
      const { rows: templates, totalPage: totalPage } = response.data.data;
      let finalDataArray = [];
      templates.map((value) => {
        let obj = {};
        let { updatedAt, createdAt, ...rest } = value;
        obj = rest;
        obj.createdAt = new Date(value?.createdAt).toJSON();
        obj.updatedAt = new Date(value?.updatedAt).toJSON();
        finalDataArray.push(obj);
      });
      dispatch(
        actions.templatesFetched({ templates: finalDataArray, totalPage })
      );
      return templates;
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign templates";
      Toaster({ message: "Can't find campaign templates", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.templateList }));
    });
};

export const updateCampaignTemplatesList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.actions }));
  return requestFromServer
    .getCampaignTemplates(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: templates,
        totalPages: totalPage,
      } = response.data.data;
      dispatch(actions.templatesFetched({ totalCount, templates, totalPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign templates";
      Toaster({ message: "Can't find campaign templates", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.actions }));
    });
};

export const removeCampaignTemplate = (values) => (dispatch) => {
  return requestFromServer
    .removeCampaignTemplateCrud(values)
    .then((response) => {
      if (response.data.message === "TEMPLATE_REMOVE_SUCCESS") {
        dispatch(actions.deleteTemplate(values));
        Toaster({
          message: "Template deleted successfully",
          variant: "dark",
        });
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't remove template";
      Toaster({
        message: "Can't remove template",
        variant: "dark",
      });
    });
};

export const increaseTemplateCount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.actions }));
  let { viewCount, templates, totalPages, ...rest } = queryParams;
  return requestFromServer
    .increaseTemplateCount(rest)
    .then((response) => {
      const newArray = templates?.map((obj) => {
        if (obj.templateId === rest.templateId) {
          return { ...obj, viewCount: viewCount + 1 };
        }
        return obj;
      });
      dispatch(
        actions.templatesFetched({ templates: newArray, totalPage: totalPages })
      );
      dispatch(actions.callSuccess({ callType: callTypes.actions }));
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't increase template count";
      Toaster({ message: "Can't increase template count", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.actions }));
    });
};

export const getTemplateDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.actions }));
  dispatch(
    actions.templateDetailsFetched({
      templateDetails: {},
      actionsLoading: true,
    })
  );
  return requestFromServer
    .getTemplateDetailsCrud(queryParams)
    .then((response) => {
      dispatch(
        actions.templateDetailsFetched({
          templateDetails: response.data.data,
          actionsLoading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.actions }));
    });
};

export const submitCampaignTemplate = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.templateList }));
  return requestFromServer
    .saveCampaignTemplate(queryParams)
    .then((response) => {
      if (response.data.message === "CAMPAIGN_TEMPLATE_UPDATED") {
        Toaster({
          message: "Template updated successfully",
          variant: "dark",
        });
      } else {
        Toaster({
          message: "Template created successfully",
          variant: "dark",
        });
      }
      dispatch(actions.callSuccess({ callType: callTypes.templateList }));
      return response;
    })
    .catch((error) => {
      Toaster({ message: "Can't create campaign template", variant: "dark" });
      error.clientMessage = "Can't create campaign template";
      dispatch(actions.catchError({ error, callType: callTypes.templateList }));
    });
};

export const emptyCampaignTemplates = (queryParams) => (dispatch) => {
  let templates = [];
  let totalCount = 0;
  let totalPage = 1;
  dispatch(actions.templatesFetched({ totalCount, templates, totalPage }));
};

export const listCampaignCategory = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignCategory(queryParams)
    .then((response) => {
      const { rows: category, total: totalCategoryCount } = response.data.data;
      dispatch(actions.categoryFetched({ category, totalCategoryCount }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign category";
      // Toaster({ message: "Can't find campaign category", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      const category = [];
      dispatch(actions.categoryFetched({ category }));
    });
};

export const filterTemplateWithCategory = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.templateList }));
  return requestFromServer
    .filterTemplateWithCategory(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: templates,
        totalPages: totalPage,
      } = response.data.data;
      dispatch(actions.templatesFetched({ totalCount, templates, totalPage }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign templates";
      Toaster({ message: "Can't find campaign templates", variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.templateList }));
    });
};

export const getCampaignDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignDetails(queryParams)
    .then((response) => {
      const campaignDetails = response.data.data;
      dispatch(actions.campaignDetailsFetched({ campaignDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.campaignDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getApplicationDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationDetails(queryParams)
    .then((response) => {
      const { rows: applicationDetails } = response.data.data;
      dispatch(actions.applicationDetailsFetched({ applicationDetails }));
      return applicationDetails;
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.applicationDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listCampaignApplications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .listCampaignApplications(queryParams)
    .then((response) => {
      const { total: totalCount, rows: applications } = response.data.data;
      dispatch(actions.applicationsFetched({ totalCount, applications }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getApplicationContent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationContent(queryParams)
    .then((response) => {
      let { total: contentCount, rows: content } = response.data.data;
      dispatch(actions.applicationContentFetched({ content, contentCount }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Application Contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const emptyCampaignDetails = () => (dispatch) => {
  const campaignDetails = {};
  dispatch(actions.campaignDetailsFetched({ campaignDetails }));
};

export const closeMyCampaign = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .closeCampaign(queryParams)
    .then((response) => {
      Toaster({ message: response?.data?.message, variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      // dispatch(actions.fetchedIntent(response.data.data));
      // return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't reject applicatuion content";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const listCompletedCampaigns = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .completedCampaignsFetched(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: campaigns,
        totalPages: totalPage,
      } = response.data.data;
      dispatch(
        actions.completedCampaignsFetched({ totalCount, campaigns, totalPage })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listFilterLevels = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .listFilterLevels(queryParams)
    .then((response) => {
      let filterLevels = [];
      const { rows } = response.data.data;
      rows?.map((val) => {
        let obj = {};
        obj.label = val.filterLevelName;
        obj.value = val.filterLevelName;
        obj.filterLevelId = val.filterLevelId;
        filterLevels.push(obj);
      });
      dispatch(actions.filterLevelsFetched({ filterLevels }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const listFilterAndSubFilters = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.filterList }));
  return requestFromServer
    .listFilterAndSubFilters(queryParams)
    .then((response) => {
      const { rows } = response.data.data;
      let finalDataArray = [];
      rows?.map((value) => {
        let obj = {};
        obj.filterLevelId = value.filterLevelId;
        obj.filterLevelName = value.filterLevelName;
        let subArray = [];
        value?.subFilterValues?.map((data) => {
          let subObj = {};
          subObj.label = data.subFilter;
          subObj.value = value.filterLevelName + " " + data.subFilter;
          subObj.subFilterId = data.subFilterId;
          subArray.push(subObj);
        });
        obj.subFilterValues = subArray;
        finalDataArray.push(obj);
      });
      dispatch(
        actions.filterAndSubFilterFetched({ filterData: finalDataArray })
      );
    })
    .catch((error) => {
      dispatch(actions.filterAndSubFilterFetched({ filterData: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.filterList }));
    });
};

export const emptyFiltersAndSubFilters = (queryParams) => (dispatch) => {
  dispatch(actions.filterAndSubFilterFetched({ filterData: [] }));
};

export const listSubFilters = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let { groupedOptions, ...rest } = queryParams;
  return requestFromServer
    .listSubFilters(rest)
    .then((response) => {
      let subFilterData = response.data.data;
      let groupedOptionsArray = [];
      if (groupedOptions.length > 0) {
        groupedOptions?.map((value) => {
          groupedOptionsArray.push(value);
        });
      }
      let finalObject = {};
      finalObject.filterLevelId = subFilterData.filterLevelId;
      finalObject.label = subFilterData.filterLevelName;
      let subFilterValuesArray = [];
      subFilterData?.subFilterValues?.map((data) => {
        let obj = {};
        obj.label = data.subFilter;
        obj.value = subFilterData.filterLevelName + " " + data.subFilter;
        obj.subFilterId = data.subFilterId;
        subFilterValuesArray.push(obj);
      });
      finalObject.options = subFilterValuesArray;
      groupedOptionsArray.push(finalObject);
      dispatch(actions.subFilterLevelsFetched({ groupedOptionsArray }));
      return groupedOptionsArray;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const listSubFilterValues = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .listSubFilters(queryParams)
    .then((response) => {
      let subFilterData = response.data.data;
      let groupedOptionsArray = [];
      let finalObject = {};
      finalObject.filterLevelId = subFilterData.filterLevelId;
      finalObject.label = subFilterData.filterLevelName;
      let subFilterValuesArray = [];
      subFilterData?.subFilterValues?.map((data) => {
        let obj = {};
        obj.label = data.subFilter;
        obj.value = subFilterData.filterLevelName + " " + data.subFilter;
        obj.subFilterId = data.subFilterId;
        subFilterValuesArray.push(obj);
      });
      finalObject.options = subFilterValuesArray;
      groupedOptionsArray.push(finalObject);
      dispatch(actions.subFilterLevelsFetched({ groupedOptionsArray }));
      return groupedOptionsArray;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setSubFilterValues = (queryParams) => (dispatch) => {
  let groupedOptionsArray = queryParams.subFilterValueArray;
  dispatch(actions.subFilterLevelsFetched({ groupedOptionsArray }));
};

export const updateSubFilters = (queryParams) => (dispatch) => {
  let groupedOptionsArray = queryParams.DataArray;
  dispatch(actions.subFilterLevelsFetched({ groupedOptionsArray }));
};
