import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CampaignUIHelpers";
import { useSelector, shallowEqual } from "react-redux";

const CampaignUIContext = createContext();

export function useCampaignUIContext() {
  return useContext(CampaignUIContext);
}

export const CampaignUIConsumer = CampaignUIContext.Consumer;

export function CampaignUIProvider({ CampaignUIEvents, children }) {
  const { userId } = useSelector(
    (state) => ({
      userId: state?.auth?.user?.userId,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    query: { brandId: userId, campaignStatus:["APPROVED_BY_ADMIN" , "CAMPAIGN_APPROVED_IN_RESTRICTED_MODE"]},
    loggedInUserId: userId,
  });
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCampaign = {
    phoneNumber: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    email: "",
    userType: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initCampaign,
    viewCampaignAction: CampaignUIEvents.viewCampaignAction,
    viewApplicationAction: CampaignUIEvents.viewApplicationAction,
    // setWaitForApproval: CampaignUIEvents.setWaitForApproval,

    // rejectCampaignAction: CampaignUIEvents.rejectCampaignAction,
    // viewApplicationContent: CampaignUIEvents.viewApplicationContent,
    // viewMediaContent: CampaignUIEvents.viewMediaContent,
  };

  return (
    <CampaignUIContext.Provider value={value}>
      {children}
    </CampaignUIContext.Provider>
  );
}
